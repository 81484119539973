/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";

import { styles } from "./ExpandableInfoParagraph.styles";
import ArrowDown from '../../../../assets/icons/arrow_down_icon.svg'
import { IconButton } from "@mui/material";


interface ExpandableInfoParagraphProps {
  icon: React.ReactNode,
  title: string;
  infoValue: string;
  defaultExpanded?: boolean
}


const ExpandableInfoParagraph: React.FC<ExpandableInfoParagraphProps> = (props) => {
  const { icon, title, infoValue, defaultExpanded } = props;
  const [expanded, setExpanded] = useState(!!defaultExpanded);
  const paragraphId = title.replaceAll(' ', '_');

  useEffect(() => {
    if (defaultExpanded) {
      const element = document.getElementById(paragraphId);
      if (element) {
        element.style.height = element.scrollHeight + 'px';
      }

    }
  }, [defaultExpanded, paragraphId]);

  function arrowClickHandler() {
    const element = document.getElementById(paragraphId);
    if (!element) {
      setExpanded(pv => !pv);
      return;
    }

    const contentHeight = element.scrollHeight + 'px';

    if (expanded) {
      element.style.height = '0'
    } else {
      element.style.height = contentHeight;
    }

    setExpanded(pv => !pv);
  }

  return (
    <section css={styles.expandableInfoParagraphWrapper}>
      {icon}
      <div css={styles.expandableInfoParagraphContent}>
        <div css={styles.expandableInfoParagraphHeader}>
          <h3>{title}</h3>
          <IconButton css={css(styles.arrowIconButton, expanded && styles.revertedArrowIcon)} color="primary"
                      onClick={arrowClickHandler}>
            <ArrowDown/>
          </IconButton>
        </div>
        <p id={paragraphId}>{infoValue}</p>
        <div css={styles.expandableInfoParagraphFooter}/>
      </div>

    </section>
  );
};

export default ExpandableInfoParagraph;
/** @jsxImportSource @emotion/react */
import React from "react";

import { styles } from "./Location.styles";
import { useTranslation } from "react-i18next";
import touchImage from '../../../assets/imgs/touch_img.png';
import mapImage from '../../../assets/imgs/map_img.png';
import ButtonSecondary from "../../../design/button-secondary/ButtonSecondary";
import { PageBlockId, Paths } from "../../../shared/constants";
import { useAppNavigation } from "../../../context/AppNavigationContext";

interface LocationProps {
}

const Location: React.FC<LocationProps> = (props) => {
  const { t } = useTranslation();
  const { scrollToBlock } = useAppNavigation();


  return (
    <section css={styles.locationWrapper}>
      <div css={styles.topPartContainer}>
        <div css={styles.headerWrapper}>
          <div/>
          <p>{t('locationPage.meetOurNumbers')}</p>
        </div>

        <img
          css={styles.touchImage}
          src={touchImage}
          alt={"We are break throgh technologies"}
        />
        <h2>{t('locationPage.weAreBreakThroghTechnologiesTitle')}</h2>
      </div>
      <div css={styles.numbersWrapper}>
        <div css={styles.numbersContainer}>
          <div css={styles.numberContainer}>
            <div>
              <p>28</p>
              <p>+</p>
            </div>
            <p>
              {t('locationPage.engagementsLabel')}
            </p>
          </div>
          <div css={styles.numberContainer}>
            <div>
              <p>50</p>
              <p>+</p>
            </div>
            <p>
              {t('locationPage.engineersLabel')}
            </p>
          </div>
          <div css={styles.numberContainer}>
            <div>
              <p>10</p>
              <p>+</p>
            </div>
            <p>
              {t('locationPage.industriesExpertiseLabel')}
            </p>
          </div>
        </div>
      </div>
      <div css={styles.mapContainer}>
        <img
          src={mapImage}
          alt={"Map "}
        />
        <h2>{t('locationPage.whereWeAreTitle')}</h2>
        <p>{t('locationPage.whereWeAreDescription')}</p>
        <h4>{t('locationPage.developmentLocationSubtitle')}</h4>
        <ul>
          <li>{t('locationPage.developmentLocationLine1')}</li>
          <li>{t('locationPage.developmentLocationLine2')}</li>
          <li>{t('locationPage.developmentLocationLine3')}</li>
        </ul>
        <ButtonSecondary onClick={() => scrollToBlock(Paths.home, PageBlockId.contact)}>
          {t('locationPage.contactUsButton')}
        </ButtonSecondary>

      </div>

    </section>
  );
}

export default Location;
/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Grid } from "@mui/material";

import { styles } from "./Teams.styles";
import { useTranslation } from "react-i18next";
import TeamImage1 from "../../../assets/imgs/team_img_1.svg";
import TeamImage2 from "../../../assets/imgs/team_img_2.svg";
import TeamImage3 from "../../../assets/imgs/team_img_3.svg";
import TeamImage4 from "../../../assets/imgs/team_img_4.svg";
import technologiesWeOfferImg from "../../../assets/imgs/technologies_we_offer_img.png";
import { PageBlockId } from "../../../shared/constants";
import { css } from "@emotion/react";


interface TeamsProps {
}

const Teams: React.FC<TeamsProps> = (props) => {
  const { t } = useTranslation();
  const [fadingGroupId, setFadingGroupId] = useState<number | null>(null);
  const [teams] = useState([
    {
      id: 1,
      img: <TeamImage1/>,
      title: t('teamsPage.teams.0.title'),
      description: t('teamsPage.teams.0.description'),
    },
    {
      id: 2,
      img: <TeamImage2/>,
      title: t('teamsPage.teams.1.title'),
      description: t('teamsPage.teams.1.description'),
    },
    {
      id: 3,
      img: <TeamImage3/>,
      title: t('teamsPage.teams.2.title'),
      description: t('teamsPage.teams.2.description'),
    },
    {
      id: 4,
      img: <TeamImage4/>,
      title: t('teamsPage.teams.3.title'),
      description: t('teamsPage.teams.3.description'),
    }
  ]);

  const [technologies] = useState([
    {
      id: 1,
      title: t('teamsPage.technologiesBackendTitle'),
      values: ['C#', 'C++', 'Scala', 'Golang', 'Java', 'Python', 'Microsoft.net', 'Node.js', 'PHP']
    },{
      id: 2,
      title: t('teamsPage.technologiesFrontendTitle'),
      values: ['Angular', 'React', 'JavaScript', 'Vue,js']
    },  {
      id: 3,
      title: t('teamsPage.technologiesMobileTitle'),
      values: ['Android', 'Ios', 'Flutter', 'React native']
    }, {
      id: 4,
      title: t('teamsPage.technologiesCloudTitle'),
      values: ['AWS', 'Azure', 'Google cloud']
    }, {
      id: 5,
      title: t('teamsPage.technologiesDataManagementSystemsTitle'),
      values: ['My SQL', 'Oracle', 'Posture SQL', 'Microsoft SQL server', 'Cosmos DB', 'Mongo DB']
    }, {
      id: 6,
      title: t('teamsPage.technologiesArtificialIntelligenceTitle'),
      values: ['Chat GPT', 'Gemini', 'Mistral AI']
    }, {
      id: 7,
      title: t('teamsPage.technologiesMachineLearningTitle'),
      values: ['Power BI', 'Tensor flow', 'Apache spark', 'Tableau', 'Data bricks', 'Looke', 'Five tran', 'Teras', 'Data IKU', 'Snow flake', 'Pytorch', 'Jupyter',]
    }
  ]);
  const [selectedTechnologyGroupIds, setSelectedTechnologyGroupIds] = useState<Array<number>>([technologies[0].id]);


  function technologiesGroupNameClickHandler(groupId: number) {
    setFadingGroupId(groupId);

    setTimeout(() => {
      // if (selectedTechnologyGroupIds.some(id => id === groupId)) {
      //   setSelectedTechnologyGroupIds((prev) => prev.filter(id => id !== groupId));
      // } else {
      //   setSelectedTechnologyGroupIds((prev) => [...prev, groupId]);
      // }
      if (selectedTechnologyGroupIds.some(id => id === groupId)){
        setSelectedTechnologyGroupIds((prev) => prev.filter(id => id !== groupId));
      }else{
        setSelectedTechnologyGroupIds([]);
        setSelectedTechnologyGroupIds((prev) => [...prev, groupId]);
      }
      setFadingGroupId(null);
    }, 200);
  }

  return (
    <section css={styles.teamsWrapper} id={PageBlockId.teams}>
      <div css={styles.teamsHeaderWrapper}>
        <h5>{t("teamsPage.teamsTitle")}</h5>
        <h2>{t("teamsPage.engagementModelsTitle")}</h2>
      </div>
      <div css={styles.teamsContainer}>
        <Grid container>
          {teams.map((t) =>
            <Grid item key={t.id} css={styles.teamContainer} xs={12} md={6} lg={3}>
              <div css={styles.teamImageContainer}>{t.img}</div>
              <h3>{t.title}</h3>
              <p>{t.description}</p>
            </Grid>)}
        </Grid>
      </div>
      <div css={styles.technologiesWeOfferWrapper}>
        <h2>{t('teamsPage.technologiesWeOfferTitle')}</h2>
        <div css={styles.technologiesWeOfferContainer}>
          <img src={technologiesWeOfferImg} alt={'Technologies we offer'}/>
          <p>{t('teamsPage.technologiesWeOfferDescription')}</p>
        </div>
      </div>
      <div css={styles.technologiesGroupWrapper}>
        <div css={styles.technologiesGroupNamesWrapper}>
          {technologies.map(tg => <React.Fragment key={tg.id}>
              <div
                css={css(styles.technologiesGroupName, selectedTechnologyGroupIds.some((id) => id === tg.id) && styles.technologiesGroupNameSelected)}
                onClick={() => technologiesGroupNameClickHandler(tg.id)}>
                {tg.title}
              </div>
              {selectedTechnologyGroupIds.some((id) => id === tg.id) &&
                <div
                  css={css(styles.technologiesSmallWrapper, fadingGroupId && fadingGroupId === tg.id && styles.technologiesWrapperFadeOut)}>
                  {tg.values
                    .map(value => <div
                      key={tg.id + '_' + value}
                      css={styles.technologySmallWrapper}>
                      {value}
                    </div>)}
                </div>
              }
            </React.Fragment>
          )}
        </div>
        {/*<div css={styles.technologiesWrapper}>*/}
        {/*  {technologies*/}
        {/*    .filter(tg => selectedTechnologyGroupIds.some(id => id === tg.id))*/}
        {/*    .map(tg => [...tg.values.map((value) => <div*/}
        {/*      key={tg.id + '_' + value}*/}
        {/*      css={css(styles.technologyWrapper, fadingGroupId && fadingGroupId === tg.id && styles.technologiesWrapperFadeOut)}>*/}
        {/*      {value}*/}
        {/*    </div>)])}*/}
        {/*</div>*/}

      </div>
    </section>
  );
}

export default Teams;